<template>
  <div>
    <Banner>
      <img style="width:502px;height:416px;bottom:26px" src="@/assets/image/productCenter/orgCert-banner-bg.png" alt="机构证书" />
      <template v-slot:text>
        <div class="title">机构证书</div>
        <div class="hint-multi">
          机构证书由豸信CA在遵循国际国内相关标准规范的基础上颁发，用来证明单位、组织在互联网上的数字身份。机构证书中包含机构信息和密钥信息，可应用于工商、税务、金融、社保、政府采购、行政办公等一系列的电子政务、电子商务活动。
        </div>
        <el-button @click="verify('certApply')" type="primary">立即申请</el-button>
      </template>
    </Banner>
    <Functional class="functional" :list="functional" />
    <Features :list="features" />
    <LearnMore />
  </div>
</template>

<script>
import Features from './components/Features.vue'
import Functional from './components/Functional.vue'
import LearnMore from './components/LearnMore.vue'
export default {
  components: { Features, Functional, LearnMore },
  data () {
    return {
      functional: [
        {
          icon: 'functional-icon1.png',
          title: '密钥匹配唯一',
          intro:
            '采用PKI/CA技术，依靠非对称加密算法中密钥对匹配的唯一性，来确保企业在互联网上的合法身份。',
        },
        {
          icon: 'functional-icon2.png',
          title: '有效电子签章',
          intro:
            '结合PKI数字证书技术，实现在各类电子文档上添加合法有效电子签章。',
        },
        {
          icon: 'functional-icon3.png',
          title: '同等法律效力',
          intro:
            '将电子印章以传统印章图案的形式嵌入到电子文档中，与相应纸质签章具备同等法律效力。',
        },
      ],
      features: [
        {
          title: '合法合规',
          intro:
            '豸信CA是合法合规的第三方CA机构，严格遵照《中华人民共和国电子签名法》和相关国际国内标准，为各类组织颁发单位证书，安全有效。',
        },
        {
          title: '安全可靠',
          intro:
            '以数字证书为核心的加密技术可以对网络上传输的信息进行加解密、数字签名、验证，确保交易实体身份真实可信。',
        },
      ],
    }
  },
  methods: {
    verify (name) {
      this.$bus.$off('afterLogin')
      this.$bus.$on('afterLogin', () => {
        this.verify(name)
      })
      this.$api.getAccountInfo().then((res) => {
        if (res.data.verified) {
          let routeData = this.$router.resolve({ name })
          window.open(routeData.href, '_blank')
        } else {
          this.$confirm('当前账号未完成管理员实名认证，实名之后才可办理业务。', '提示', {
            confirmButtonText: '实名认证',
            cancelButtonText: '暂不认证',
            type: 'warning'
          }).then(() => {
            this.$router.push({ path: '/personHome' })
          }).catch(() => {

          });
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.functional {
  .item {
    width: 372px;
    height: 358px;
    padding: 32px 42px;
  }
}
</style>
